    .top-strip {
      background-image: linear-gradient(to bottom, #f3bb04, #ff3131);;
      color: white; /* White text color */
      display: flex; /* Flexbox for positioning */
      justify-content: space-between; /* Space out items */
      align-items: center; /* Center items vertically */
      padding: 10px 20px; /* Add some padding */
      /*position: fixed;  Keep it fixed at the top */
      top: 0;
      width: 100%; /* Make it span across the entire page */
      z-index: 1000; /* Ensure it's on top of other content */
      height: 28px;
    }
    /* Style for the mailto link */
    .mailto-link {
      text-decoration: none; /* Remove underline from the mailto link */
      color: white; /* White text */
      font-weight: bold; /* Make the text bold */
      font-size:x-small;
      cursor: pointer;
      margin-left:50px;
    }
    /* Style for social media icons */
    .social-icons a {
      color: white; /* White icon color */
      font-size: 18px; /* Size for the icons */
      margin-left: 15px; /* Space between icons */
      text-decoration: none; /* Remove underline */
      justify-content: center;
      position: relative;
      top: 4px;
    }

    .social-icons {
        margin-right:70px;
    }

    .socialLogo{
        height: 20px;
        justify-content: center;

    }