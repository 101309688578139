.container1 {
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px;
    gap: 20px;
  }

  /* Address Card */
  .address-card {
    background-color: #ffffff;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 45%;
  }

  .address-card h2 {
    color: #6a4c3c; /* Olive Green */
    margin-bottom: 15px;
    font-size: 24px;
  }

  .address-card p {
    font-size: 16px;
    line-height: 1.8;
    color: #555;
  }

  /* Form Card */
  .form-card {
    background-color: #d3d3d3; /* Grey Background */
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 45%;
  }

  .form-card h2 {
    color: #6a4c3c; /* Olive Green */
    margin-bottom: 15px;
    font-size: 24px;
  }

  .form-group {
    margin-bottom: 20px;
  }

  .form-group label {
    font-size: 16px;
    font-weight: bold;
    display: block;
    margin-bottom: 5px;
  }

  /* Input Fields */
  .form-group input,
  .form-group textarea {
    width: 100%;
    padding: 12px;
    font-size: 16px;
    border-radius: 0; /* Square corners */
    border: 1px solid #ccc;
    outline: none;
    transition: border-color 0.3s;
  }

  .form-group input:focus,
  .form-group textarea:focus {
    border-color: #6a4c3c; /* Olive Green */
  }

  .form-group textarea {
    height: 150px;
    resize: none;
  }

  /* Submit Button */
  .submit-btn {
    display: block;
    width: 100%;
    padding: 12px;
    background-color: #9b59b6; /* Purple */
    color: white;
    font-size: 18px;
    border-radius: 6px;
    border: none;
    cursor: pointer;
    transition: transform 0.2s ease, background-color 0.3s ease;
  }

  .submit-btn:hover {
    background-color: #6a4c3c; /* Olive Green */
    transform: scale(1.1);
  }

  .submit-btn:active {
    transform: scale(1);
  }

  /* Footer Section */
  footer {
    background-color: #6a4c3c; /* Olive Green */
    color: white;
    text-align: center;
    padding: 20px;
    margin-top: 50px;
  }

  header {
    width: 100%;
    height: 500px; /* Adjust the height of the image */
    background-size: cover;
    background-position: center;
    margin-bottom: 0px; /* Space between the image and content */
  }

  .success-message {
    background-color: #4CAF50; /* Green */
    color: white;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    margin-top: 20px;
    font-size: 18px;
    font-weight: bold;
  }
  .textoverimage{
    position: absolute;
    top: 50%;
    left: 10vw;
    font-weight: bold;
    font-size: 43px;
  }