  .primary {
    position: relative;
    width: 54%;
    height: 370px;
    background-color: #004aad;
    border-radius: 8px;
    margin-right: 41%;
    margin-top: 3%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 20px;
    font-weight: bold;
    float: right;
  }
  
  .secondary {
    position: absolute;
    width: 78%;
    height: 87%;
    background-image: url("../logos/backgrounddiv.png");
    background-size:contain;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    bottom: -34%;
    left: 79%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 14px;
    font-weight: bold;
    z-index: -1;
  }
  
  .slide-left {
    animation:  1.5s fade-out;
  }

  .slide-right {
    animation:  1.5s fade-in;
  }
  
  @keyframes slide-left {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0);
    }
  }
  
  .fade-out {
    animation: 1s fadeOutUp;
  }
  
  @keyframes fade-out {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .clist-container {
    list-style: none;
    margin-left: 100px;
}
.clist-item {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}
.ccircle {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(145deg, #e0e0e0, #ffffff);
    border-radius: 50%;
    box-shadow: inset 3px 3px 5px #bebebe, inset -3px -3px 5px #ffffff;
    font-weight: bold;
    font-size: 16px;
    color: #333;
    margin-right: 15px;
}
.ctext {
    font-size: 20px;
    color: #fff;
}

.hover:hover{
  background-color: #ff5733;
  color:#fff
} 
  