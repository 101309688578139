.column {
    float: left;
    width: 50%;
    padding: 10px;
    height: 34px; /* Should be removed. Only for demonstration */
    color: orange;
  }

  .logo{
    height:48px;
    position: relative;
    left: -60px;
  }

  .navlink{
    margin: 0px 15px;
    color:#1B5177;
    text-decoration: none;
    cursor: pointer;
    font-weight: bold;
  }

  .navlink.active{
    color:orange
  }
  
  .navlink:hover{
    color:blues
  }
  
  .row{
    top:-8px;
    position: relative;
  }
  /* Clear floats after the columns */
  .row:after {
    content: "";
    display: table;
    clear: both;
  }

  .logo {
    font-size: 28px;
    font-weight: 600;
    color: orange; /* Olive Green Color */
    text-transform: uppercase;
    letter-spacing: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
    position: relative;
    left:-88px;
  }

  .logo span {
    color: #366581; /* Purple Color */
    transition: color 0.3s ease;
  }

  .logo1:hover {
    transform: scale(1.1); /* Slightly scale the logo */
  }

  .logo1:hover span {
    color: #f39c12; /* Change color of "Software Services" to gold on hover */
  }

  /* Adding underline effect on hover */
  .logo1::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 3px;
    background-color: #9b59b6; /* Purple underline */
    transition: width 0.3s ease;
  }

  .logo1:hover::after {
    width: 100%; /* Underline expands on hover */
  }