 /* General styling */
 body {
    font-family: 'Poppins', sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f4f7fc;
    color: #333;
  }

  /* Hero Section */
  .hero {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 100px 50px;
    background-color: #2d3e50;
    color: white;
    animation: fadeIn 1s ease-in-out;
  }

  .hero h1 {
    font-size: 50px;
    font-weight: 600;
    animation: slideInLeft 1s ease-out;
  }

  .hero p {
    font-size: 18px;
    width: 60%;
    line-height: 1.6;
    margin: 20px 0;
    animation: slideInLeft 1.2s ease-out;
  }

  .cta-btn {
    background-color: #f39c12;
    color: white;
    border: none;
    padding: 15px 30px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    animation: fadeIn 1.5s ease-in-out;
  }

  .cta-btn:hover {
    background-color: #e67e22;
  }

  /* Features Section */
  .features {
    display: flex;
    justify-content: space-around;
    padding: 10px;
    
    text-align: center;
    margin-bottom: 10px;;
    
  }

  .features1 {
    display: flex;
    justify-content: space-around;
    padding: 20px;
    text-align: center;
  }

  .ulist{
    top: -61px;
    position:relative;
    animation: float 3s infinite ease-in-out;
  }

  .ulist:hover{
    animation-play-state: paused;
  }

  .feature-card {
    
    width: 250px;
   
    transition: transform 0.3s ease;
    cursor: pointer;
  }

  section{
    margin-bottom: 0px;;
  }

  .feature-card:hover {
    transform: translateY(-10px);
  }

  .feature-card h3 {
    font-size: 22px;
    margin: 10px 0;
    top:15vh;
    position: relative;
  }

  /* Testimonials Section */
  .testimonials {
    background-color: #2d3e50;
    color: white;
    padding: 50px 0;
    text-align: center;
  }

  .testimonials h2 {
    font-size: 36px;
    margin-bottom: 30px;
  }

  .testimonial-carousel {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .testimonial {
    background-color: #f4f4f4;
    padding: 20px;
    border-radius: 10px;
    width: 300px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    animation: slideInUp 1s ease-out;
  }

  /* Contact Section */
  .contact {
    padding: 50px;
    text-align: center;
    background-color: #f4f7fc;
  }

  .contact form {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 500px;
    margin: 0 auto;
  }

  .contact input,
  .contact textarea {
    width: 100%;
    padding: 15px;
    margin: 10px 0;
    border-radius: 5px;
    border: 1px solid #ddd;
  }

  .contact button {
    background-color: #f39c12;
    color: white;
    padding: 15px 30px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .contact button:hover {
    background-color: #e67e22;
  }

  /* Footer Section */
  .footer {
    background-color: #2d3e50;
    color: white;
    text-align: center;
    padding: 20px;
  }

  /* Animations */
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes slideInLeft {
    from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(0);
    }
  }

  @keyframes slideInUp {
    from {
      transform: translateY(50px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }

  .outercontainer{
    width:100%;
  }

  .homecontainer {
    position: relative;
    width: 100%;
    width: 100%;
    border-radius: 10px; /* Rounded corners */
    display: flex;
    justify-content: flex-end;
    margin-bottom: 200px;
  }
  
  /* Flex container for cards */
  .card-container {
    display: flex;
    transition: transform 0.5s ease;
  }
  
  .card {
    min-width: 100%;
    margin: 1px;
    height: 84vh;
    background-image: linear-gradient(to bottom, #12283f, #366581);;
    color: white;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
    overflow: hidden;
  }
  
  /* Content inside the card */
  .card1 img {
    width: 100%;
    height: 100%;
    object-fit: fill;
    filter: brightness(100%); /* Dim the image for better text visibility */
  }
  
  .card .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    color: white;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
  }
  
  .card h3 {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .card p {
    font-size: 18px;
    line-height: 1.6;
  }
  
  /* Dots for navigation */
  .dots {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 0px;
    position: relative;
    top:0px;
  }
  
  .dot {
    width: 15px;
    height: 15px;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .dot.active {
    background-color: #1D557A;
  }


  .gradient-button {
    padding: 10px 20px;
    font-size: 18px;
    font-weight: bold;
    color: white;
    background: linear-gradient(45deg, #ff7f50, #ff4500); /* Orange gradient */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.5s ease, transform 0.3s ease;
    outline: none;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .gradient-button:hover {
    background: linear-gradient(45deg, #1e90ff, #00bfff); /* Blue gradient on hover */
    
  }
  
  /* Focus effect */
  .gradient-button:focus {
    outline: none;
    
  }
  
  .gradient-button:active {
    transform: scale(1); /* Button returns to normal size when clicked */
  }

  .cardcontainer2 {
    display: flex; /* Flexbox for side-by-side layout */
    align-items: center; /* Center vertically */
    justify-content: center;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    height: 100%;
    transition: transform 0.3s ease; /* Smooth transition for hover effect */
}

.cardcontainer2 img {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    transition: transform 0.3s ease; /* Smooth animation on hover */
    cursor: pointer;
}

.text {
  max-width: 500px;
}

.text h2 {
  font-size: 28px;
  color: #333;
  margin-bottom: 10px;
  font-weight: 600;
  opacity: 0;
  animation: fadeIn 1s forwards 0.5s; /* Animation for title */
}

.text p {
  font-size: 16px;
  color: #555;
  line-height: 1.6;
  opacity: 0;
  animation: fadeIn 1s forwards 1s; /* Animation for paragraph */
}

.features3{
  margin-top: 80px;
  margin-bottom: 60px;
}

.animated-text {
  font-size: 2rem;
  font-weight: bold;
  text-align: left;
  margin-left: 7%;;
  position: relative;
  color:#000;
  margin-bottom: 20px;
  opacity: 0;
  transform: scale(0.8);
  animation: fadeInScale 2s ease-out forwards;
  
}

@keyframes fadeInScale {
  0% {
      opacity: 0;
      transform: scale(0.8);
  }
  50% {
      opacity: 0.5;
      transform: scale(1.05);
  }
  100% {
      opacity: 1;
      transform: scale(1);
  }
}

/* Keyframes for fade-in animation */
@keyframes fadeIn {
  from {
      opacity: 0;
      transform: translateY(20px);
  }
  to {
      opacity: 1;
      transform: translateY(0);
  }
}


@keyframes float {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
}


.contactform-container {
  
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  width: 100%;
  max-width: 600px;
  font-family: 'Salesforce Sans', Arial, sans-serif;
}

.contactform-container h2 {
  margin-bottom: 20px;
  color: #0568AE;
  font-size: 28px;
  text-align: center;
  font-weight: bold;
}

.contactform-group {
  margin-bottom: 20px;
}

.contactform-group label {
  display: block;
  margin-bottom: 5px;
  font-size: 14px;
  color: #5E5E5E;
  font-weight: bold;
}

.contactform-group input,
.contactform-group textarea,
.contactform-group select {
  width: 100%;
  padding: 12px;
  font-size: 16px;
  border: 1px solid #D8D8D8;
  border-radius: 4px;
  box-sizing: border-box;
  outline: none;
  transition: all 0.3s ease;
  background: #FAFAFA;
}

.contactform-group input:focus,
.contactform-group textarea:focus,
.contactform-group select:focus {
  border-color: #0568AE;
  background: #ffffff;
  box-shadow: 0 0 4px rgba(5, 104, 174, 0.5);
}

.contactform-group textarea {
  resize: none;
  height: 120px;
}

.submit-btn1 {
  background: #0568AE;
  color: #ffffff;
  border: none;
  padding: 12px 20px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease;
  width: 100%;
  text-transform: uppercase;
}

.submit-btn1:hover {
  background: #045a94;
}

.submit-btn1:active {
  background: #03497b;
}

.contactform-container small {
  display: block;
  text-align: center;
  margin-top: 10px;
  font-size: 12px;
  color: #888;
}

.outer-contact-container{
  display: flex;
  justify-content: center;
}


.image-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.image-container div {
  width: 150px;
  height: 150px;
  border: 3px solid #ccc;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  background: #fff;
}

.image-container div img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

