.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  line-height: 1.6;
  color: #333;
}

header {
  background: #333;
  color: #fff;
  padding: 1rem 0;
}

header .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

header h1 {
  margin: 0;
}

nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

nav ul li {
  margin-left: 20px;
}

nav ul li a {
  color: #fff;
  text-decoration: none;
}

main {
  padding: 2rem 0;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

section {
  margin-bottom: 2rem;
}

h2 {
  color: #333;
  border-bottom: 2px solid #ddd;
  padding-bottom: 0.5rem;
}

ul {
  list-style: none;
  padding: 0;
}

ul li {
  margin: 0.5rem 0;
}

.portfolio-items {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.portfolio-item {
  flex: 1 1 calc(33.333% - 20px);
  border: 1px solid #ddd;
  padding: 1rem;
  border-radius: 5px;
}

.portfolio-item h3 {
  margin-top: 0;
}

form {
  display: flex;
  flex-direction: column;
}

form label {
  margin-bottom: 0.5rem;
}

form input,
form textarea {
  margin-bottom: 1rem;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 5px;
}

form button {
  background: #333;
  color: #fff;
  padding: 0.5rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

footer {
  background: #333;
  color: #fff;
  text-align: center;
  padding: 1rem 0;
  margin-top: 2rem;
}

* {
box-sizing: border-box;
}

.column {
float: left;
width: 33.33%;
padding: 5px;
}

/* Clearfix (clear floats) */
.row::after {
content: "";
clear: both;
display: table;
}

.img{
  width:100%;height:230px
}

