.tile-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    padding: 40px;
    max-width: 1200px;
    margin: auto;
  }
  
  .tile {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    padding: 20px;
    text-align: center;
    opacity: 0; /* Initially hidden */
    transform: translateY(30px); /* Initial position for animation */
    transition: opacity 1s, transform 1s;
  }
  
  .tile h3 {
    font-size: 24px;
    color: #333;
    margin-bottom: 10px;
  }
  
  .tile p {
    font-size: 16px;
    color: #555;
  }
  
  .tile.visible {
    opacity: 1;
    transform: translateY(0); /* Move to original position */
  }
  
  .tile:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 18px rgba(0, 0, 0, 0.1);
  }

  .cardcontainer1 {
    display: flex; /* Flexbox for side-by-side layout */
    align-items: center; /* Center vertically */
    justify-content: center;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    height: 100%;
    transition: transform 0.3s ease; /* Smooth transition for hover effect */
}

.cardcontainer1 img {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    margin-right: 30px;
    transition: transform 0.3s ease; /* Smooth animation on hover */
    cursor: pointer;
}