/* Page Container */
.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }

  /* Carousel Section */
  .carousel-container {
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-bottom: 40px;
  }

  .carousel {
    display: flex;
    width: 300%;
    transition: transform 0.5s ease-in-out;
  }

  .carousel-slide {
    width: 100%;
    height: 400px;
    background-position: center;
    background-size: cover;
    background-color: #333;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 32px;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.7);
    text-align: center;
  }

  .carousel-slide:nth-child(1) {
    background-image: url('https://via.placeholder.com/1200x400?text=Innovation');
  }
  .carousel-slide:nth-child(2) {
    background-image: url('https://via.placeholder.com/1200x400?text=Excellence');
  }
  .carousel-slide:nth-child(3) {
    background-image: url('https://via.placeholder.com/1200x400?text=Growth');
  }

  /* Carousel Controls */
  .carousel-control {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    font-size: 36px;
    padding: 10px;
    cursor: pointer;
    z-index: 10;
  }

  .carousel-control.prev {
    left: 10px;
  }

  .carousel-control.next {
    right: 10px;
  }

  /* About Section with Cards */
  .about-cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
    margin-top: 40px;
  }

  .about-card {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
  }

  .about-card:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  }

  .about-card h2 {
    color: #6a4c3c; /* Olive Green */
    font-size: 24px;
    margin-bottom: 15px;
  }

  .about-card p {
    font-size: 16px;
    color: #555;
  }

  /* Hierarchy Tree Section */
  .hierarchy-tree {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    padding: 50px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .tree-node {
    text-align: center;
    margin: 20px;
    padding: 20px;
    background-color: #9b59b6; /* Purple */
    color: white;
    border-radius: 8px;
    font-size: 16px;
    width: 150px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
  }

  .tree-node:hover {
    background-color: #6a4c3c; /* Olive Green */
    cursor: pointer;
    transform: scale(1.1);
  }

  /* Connecting Lines */
  .tree-line {
    width: 50px;
    height: 20px;
    border-top: 3px solid #6a4c3c; /* Olive Green */
    margin: 0 auto;
  }

  .tree-line-vertical {
    width: 20px;
    height: 50px;
    border-left: 3px solid #6a4c3c;
    margin: 0 auto;
  }

  /* Animation for tree nodes */
  .tree-node {
    opacity: 0;
    transform: translateY(50px);
    animation: fadeInUp 1s forwards;
  }

  .tree-node:nth-child(1) {
    animation-delay: 0.2s;
  }

  .tree-node:nth-child(2) {
    animation-delay: 0.4s;
  }

  .tree-node:nth-child(3) {
    animation-delay: 0.6s;
  }

  .tree-node:nth-child(4) {
    animation-delay: 0.8s;
  }

  /* Keyframe for tree node animation */
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(50px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  /* Footer Section */
  footer {
    background-color: #6a4c3c; /* Olive Green */
    color: white;
    text-align: center;
    padding: 20px;
    margin-top: 50px;
  }

  header {
    width: 100%;
    height: 500px; /* Adjust the height of the image */
    background-size: cover;
    background-position: center;
    margin-bottom: 0px; /* Space between the image and content */
  }

  .textoverimage{
    position: absolute;
    top: 50%;
    left: 10vw;
    font-weight: bold;
    font-size: 43px;
  }