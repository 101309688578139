.textoverimage{
    position: absolute;
    top: 50%;
    left: 10vw;
    font-weight: bold;
    font-size: 43px;
  }
  header {
    width: 100%;
    height: 500px; /* Adjust the height of the image */
    background-size: cover;
    background-position: center;
    margin-bottom: 0px; /* Space between the image and content */
  }

  .circle-container {
    position: relative;
    width: 400px; /* Width of the circular container */
    height: 400px; /* Height of the circular container */
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transform-style: preserve-3d;
  }
  
  .tileservice {
    position: absolute;
    width: 100px;
    height: 100px;
    background-color:#fff;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-weight: bold;
  }
  
  .tileservice:nth-child(1) {
    transform: rotate(0deg) translateX(150px) rotate(0deg);
  }
  
  .tileservice:nth-child(2) {
    transform: rotate(60deg) translateX(150px) rotate(-60deg);
  }
  
  .tileservice:nth-child(3) {
    transform: rotate(120deg) translateX(150px) rotate(-120deg);
  }
  
  .tileservice:nth-child(4) {
    transform: rotate(180deg) translateX(150px) rotate(-180deg);
  }
  
  .tileservice:nth-child(5) {
    transform: rotate(240deg) translateX(150px) rotate(-240deg);
  }
  
  .tileservice:nth-child(6) {
    transform: rotate(300deg) translateX(150px) rotate(-300deg);
  }


  .topiccontainer {
    display: flex;
    width: 100%;
  }
  .left-panel {
    width: 250px;
    background-color: #2c3e50;
    color: white;
    padding: 20px;
    height: 100vh;
  }
  
  .left-panel ul {
    list-style-type: none;
    padding: 0;
  }
  
  .left-panel ul li {
    padding: 15px;
    cursor: pointer;
    font-size: 1.2rem;
    border-bottom: 1px solid #34495e;
  }
  
  .left-panel ul li:hover {
    background-color: #34495e;
  }
  
  .right-panel {
    flex: 1;
    padding: 20px;
    background-color: #ecf0f1;
    height: 100vh;
    overflow-y: auto;
  }
  
  .content-panel {
    display: none;
  }
  
  .content-panel.active {
    display: block;
  }
  
  h2 {
    font-size: 2rem;
    margin-bottom: 10px;
  }
  
  p {
    font-size: 1.2rem;
    line-height: 1.6;
  }

  .contentcontainer {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
}

.iframe {
    width: auto;
    height: auto;
    margin: 0 0 10px 20px;
    float: right;
}

.text {
    flex: 1;
}