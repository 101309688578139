.servicecontainer {
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 0.6s ease, transform 0.6s ease;
    flex-wrap: nowrap;
    
}


.servicetile {
    width: 250px;
    height: 250px;
    perspective: 1000px;
    margin: 0 30px;
}

.tile-inner {
    width: 100%;
    height: 100%;
    position: relative;
    transform-style: preserve-3d;
    transform: rotateY(0);
    transition: transform 0.9s ease;
    background-color: white;
}

.servicetile:hover .tile-inner {
    transform: rotateY(180deg);
    cursor:pointer;
}

.tile-front, .tile-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
    cursor: pointer;
}

.tile-front {
    background-color:#004aad;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
}

.tile-back {
    background-color: #004aad;
    color: #fff;
    font-size: 18px;
    transform: rotateY(180deg);
    cursor: default;
}

.know-more-button {
     background-color: #fff; 
    color: #004aad;
    font-size: 18px;
    font-weight: bold;
    border: none;
    border-radius: 25px;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    position:absolute;
    top:70%;
    left:20%;

}

.know-more-button:hover {
    background-color: #ff5733;
    color:#fff
}

.balloon {
    width: 150px;
    height: 200px;
    background: radial-gradient(circle at top, #ff6f61, #ff4b2b);
    border-radius: 50% 50% 45% 45%;
    position: relative;
    box-shadow: inset -10px -10px 20px rgba(255, 255, 255, 0.6), 10px 10px 20px rgba(0, 0, 0, 0.2);
    transform: translateZ(0);
    perspective: 1000px;
}

.string {
    width: 2px;
    height: 150px;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    background: none;
}

.string::before {
    content: '';
    position: absolute;
    width: 2px;
    height: 150px;
    background-color: #333;
    border-radius: 50%;
    animation: wave 2s infinite ease-in-out;
}

@keyframes wave {
    0% {
        transform: translateX(0) rotate(0deg);
    }
    25% {
        transform: translateX(-5px) rotate(-5deg);
    }
    50% {
        transform: translateX(0) rotate(0deg);
    }
    75% {
        transform: translateX(5px) rotate(5deg);
    }
    100% {
        transform: translateX(0) rotate(0deg);
    }
}

.balloon::before {
    content: '';
    position: absolute;
    top: 30px;
    left: 40px;
    width: 30px;
    height: 30px;
    background: rgba(255, 255, 255, 0.7);
    border-radius: 50%;
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.8);
}


.balloon-container:hover .balloon {
    animation: blast 0.9s ease-out forwards;
}

@keyframes blast {
    0% {
        transform: scale(1.5);
    }
    100% {
        transform: scale(2);
        opacity: 1;
    }
}

.balloon-container {
    position: relative;
    width: 150px;
    height: 200px;
}

p{
    display: flex
;
    position: relative;
    justify-content: center;
    top: 70px;
    text-align: center;
}


.circle-container1 {
    position: absolute;
    width: 232px;
    top: -150px;
}

.circle-item1 {
    display: flex;
    align-items: center;
    gap: 25px;
    margin-left:18px;
    margin-top:5px;
}


.text1 {
    font-size: 14px;
    align-items: center;
    color: #fff;
    font-weight: bold;
}

.pointcontainer{
    position: absolute;
}



.buttonmore:hover {
    background-color: #004aad;
    color: #fff;
}
.buttonmore:active {
    transform: translateY(0);
}
.buttonmore {
    position: relative;
    bottom: 60px;
    left: 82%;
    margin-right: 62px;
    
    margin-bottom: 20px;
    color: #004aad;
    padding: 10px 20px;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 85px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
    transition: background-color 0.3s, transform 0.2s;
}
