footer {
    background-color: #004aad; /* Olive Green */
    color: white;
    display: flex;
    justify-content: space-between;
    padding: 40px;
    flex-wrap: wrap;
    margin-top: 50px;
  }

  footer .section {
    width: 22%;
    padding: 10px;
  }

  footer h3 {
    margin-bottom: 15px;
    font-size: 20px;
    color: #fff;
  }

  footer p,
  footer a {
    font-size: 14px;
    color: #ddd;
    margin-bottom: 10px;
    text-decoration: none;
  }

  footer a:hover {
    color: #fff;
    text-decoration: underline;
  }

  /* Map Embed */
  .map-container {
    width: 100%;
    height: 300px;
    border-radius: 8px;
    overflow: hidden;
    margin-top: 20px;
  }

  /* Links Styling */
  .links a {
    display: block;
  }

  /* Responsive Design */
  @media (max-width: 768px) {
    footer .section {
      width: 48%;
      margin-bottom: 20px;
    }
  }

  @media (max-width: 480px) {
    footer .section {
      width: 100%;
    }
  }

  /* Copyright Section */
  .copyright {
    width: 100%;
    text-align: center;
    padding-top: 20px;
    font-size: 14px;
    margin-top: 30px;
    border-top: 1px solid #ddd;
  }